import AsyncSelect from 'react-select/async';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { Eyeglasses } from 'react-bootstrap-icons';
import { useAtom } from 'jotai';
import { CTheme, bgVariant, isDark } from 'data/theme';
import { delay } from 'utils/functions';

export default function SearchForm() {
    const [theme] = useAtom(CTheme);
    const [variant] = useAtom(bgVariant);
    const [isDarkTheme] = useAtom(isDark);
    const borderClass = isDarkTheme ? 'border-secondary' : '';

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]

    const handleSearch = async(searchValue) => {
        await delay(4000);
        return options.filter(option => option.label.toLowerCase().includes(searchValue.toLowerCase()));
    }

    return (
        <div>
            <AsyncSelect
                cacheOptions
                loadOptions={handleSearch}
                loadingMessage={() => 'Searching...'}
                noOptionsMessage={() => 'No results found'}
                placeholder="Search collections or contract"
                isLoading={false}
                className="w-100"
            />
        </div>
       
    )
}


function SearchFormOld() {
    const [theme] = useAtom(CTheme);
    const [variant] = useAtom(bgVariant);
    const [isDarkTheme] = useAtom(isDark);
    const borderClass = isDarkTheme ? 'border-secondary' : '';

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <Form onSubmit={handleSubmit} className="">
            <Form.Group className="me-auto" controlId="search-form">
                <InputGroup className={`mb-3 border border-1 ${borderClass} rounded-3`}>
                    <Button variant={variant} id="button-addon2">
                        <Eyeglasses size={40} />
                    </Button>
                    <Form.Control type="text" className={`${theme} border border-0 input-focus-disabled`} size="lg" placeholder="Search collections or contract" />
                    
                </InputGroup>
            </Form.Group>

        </Form>
    )
}
