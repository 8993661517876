import { UnsupportedChainIdError } from '@web3-react/core'
import { InjectedConnector, NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected } from '@web3-react/injected-connector';
import { NetworkConnector } from '@web3-react/network-connector';
import { WalletConnectConnector, UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from '@web3-react/walletconnect-connector';

export const RPC_URLS = {
  1: 'https://eth-goerli.alchemyapi.io/v2/a84ZGD_z7xOy4eUBJmJxE05cqi3QFb0x',
  5: 'https://eth-goerli.alchemyapi.io/v2/a84ZGD_z7xOy4eUBJmJxE05cqi3QFb0x',
  // 56: 'https://bsc-dataseed.binance.org/',
}

export const networkExplorer = {
  1: {
    address: 'https://etherscan.io/address/',
    tx: 'https://etherscan.io/tx/',
    block: 'https://etherscan.io/block/'
  },
  5: {
    address: 'https://goerli.etherscan.io/address/',
    tx: 'https://goerli.etherscan.io/tx/',
    block: 'https://goerli.etherscan.io/block/',
  },
  /*56: {
    address: 'https://bscscan.com/address/',
    tx: 'https://bscscan.com/tx/',
    block: 'https://bscscan.com/block/',
  },
 */
}

export const chainIdColor = {
  1: {
    text: 'text-light',
    bg: 'bg-dark'
  },
  5: {
    text: 'text-light',
    bg: 'bg-primary'
  },
  /*
  56: {
    text: 'text-dark',
    bg: 'bg-warning'
  },
 */
}

/* CONNECTORS */

export const chainIdName = {
  1: 'Ethereum Mainnet',
  5: 'Goerli Test Network',
 /* 56: 'BSC Mainnet'*/
}

export const injected = new InjectedConnector({ supportedChainIds: [1, 5] })

export const network = new NetworkConnector({
  urls: RPC_URLS,
  defaultChainId: 1
})

export const walletconnect = new WalletConnectConnector({
  rpc: { 1: RPC_URLS[1], 5: RPC_URLS[5] },
  qrcode: true
})

/* ERROR GETTER */
export const getErrorMessage = (error) => {
  if (error instanceof NoEthereumProviderError) {
  
    return 'No injected provider detected';

  } else if (error instanceof UnsupportedChainIdError) {
  
    return 'You are connected to an unsupported network';
  
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorWalletConnect
  ) {
    return 'User denied account access';
  
  } else {
      
    return 'An unknown error occurred';
  }
}

export const getAddressExplorerLink = (account, chainId) => {
    const explorer = networkExplorer[chainId];

    if (explorer) {
      return `${explorer.address}${account}`;
    }

    return `#`;
}

export const getTransactionExplorerLink = (txHash, chainId) => {
    const explorer = networkExplorer[chainId];

    if (explorer) {
      return `${explorer.tx}${txHash}`;
    }

    return `#`;
}

export const getBlockExplorerLink = (block, chainId) => {
    const explorer = networkExplorer[chainId];

    if (explorer) {
      return `${explorer.block}${block}`;
    }

    return `#`;
}