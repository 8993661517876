import { atomWithSessionStorage } from './atom.local';

export const marketplaceList = atomWithSessionStorage('marketplaceList', []);
export const marketplaceCursor = atomWithSessionStorage('marketplaceListCursor', 0);
export const marketplaceFilter = atomWithSessionStorage('marketplaceFilter', {
    auction: true,
    buynow: true,
    // price: '1', 
    listedDate: 'DESC',
    tokenSchema: 'ERC721',
    startPrice: '',
    endPrice: '',
});
export const bidList = atomWithSessionStorage('bidList', []);
export const bidCursor = atomWithSessionStorage('bidListCursor', 0);
export const currenciesList = atomWithSessionStorage('currenciesList', []);