import { atom } from 'jotai';
import atomWithLocalStorage from './atom.local';

export const isDark = atomWithLocalStorage('theme', false); // light is the default
export const CText = atom(get => get(isDark) ? 'text-light' : 'text-dark'); // text class
export const CBg = atom(get => get(isDark) ? 'bg-dark' : 'bg-light'); // background class
export const CTheme = atom(get => `${get(CText)} ${get(CBg)}`); // theme class

export const foreVariant = atom(get => get(isDark) ? 'light' : 'dark'); // text variant;
export const bgVariant = atom(get => get(isDark) ? 'dark' : 'light'); // bg variant;
export const CardVariant = atom(get => get(isDark) ? 'dark' : 'light');
export const borderVariant = atom(get => get(isDark) ? 'secondary' : '');
export const modalClass = atom(get => get(isDark) ? 'bg-dark text-light' : 'bg-light text-dark');