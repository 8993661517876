import { Row, Col, Card, Image } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { useAtom } from 'jotai';
import { defaultFiatCurrency } from 'data/account';
import { fiatCurrencies } from 'app.config';

export default function FiatSelectModal() {
    const [userFiatCurrency, setUserFiatCurrency] = useAtom(defaultFiatCurrency);

    return (
        <Row className="fiat-select-modal justify-content-center">
           {
            fiatCurrencies.map(({name, iso, symbol, image}) => {
                const isSelected = iso.toLowerCase() === userFiatCurrency.toLowerCase();
                return (
                    <Col xs={10} md={6} key={iso}>
                        <Card.Body 
                            className={`d-flex align-items-center pointer my-1 ${isSelected ? 'fiat-currency-selected' : 'fiat-currency-select'}`}
                            onClick={() => setUserFiatCurrency(iso)}
                        >
                            <div className="me-2">
                                <Image src={image} width={28} className="border border-1" roundedCircle />
                            </div>
                            <p className="h6">
                                {name}<br />
                                <span className="text-muted">{iso.toUpperCase()} - {symbol}</span>
                                {isSelected && <CheckCircleFill className="text-secondary ms-3" />}
                            </p>
                        </Card.Body>
                    </Col>
                )
            })
           }
        </Row>
    )
}