export const deepClone = (obj) => {
	if (Array.isArray(obj)) {
		var arr = [];
		for (var i = 0; i < obj.length; i++) {
			arr[i] = deepClone(obj[i]);
		}
		return arr;
	}

	if (typeof(obj) === 'object'){
		var cloned = {};
		for(let key in obj){
			cloned[key] = deepClone(obj[key])
		}
		return cloned;	
	}
	return obj;
}

export const mergeNftAndCollections = (collections, newCollections, matcher = '_id') => {
	/* 
	collections: array of  existing collections items
	newCollections: array of new collections items to be merged
	*/
	const newCollectionList = deepClone(collections);
	newCollections.forEach(collection => {
		const index = newCollectionList.findIndex(c => c[matcher] === collection[matcher]);
		if (index === -1) {
			newCollectionList.push(collection);
		} else {
			newCollectionList[index] = collection;
		}
	})
	return newCollectionList;
}

export const cutString = (str, length) => {
	if (str.length <= length) return str;
	return str.substring(0, length) + '...';
}

export const randomIndex = (max = 5) => {
	return Math.floor(Math.random() * max);
}

export const onlyAlphaNumeric = (str) => {
	return str.replace(/[^a-zA-Z0-9]/g, '');
}

export const delay = (ms) => {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export const dateToHtmlInput = (date = new Date()) => {
	if (!date) return '';
	const d = new Date(date);
	return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
}

/* 
 * a and b are javascript Date object 
 * source: https://stackoverflow.com/a/15289883/3186314
*/
export const dateDifferenceInDays = (a, b) => {
	const _MS_PER_DAY = 1000 * 60 * 60 * 24;
	// Discard the time and time-zone information.
	const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
	const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  
	return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export const dateToRelativeDayAndHour = (_date = new Date()) => {
	const date = new Date(_date);
	const now = new Date();
	const daysDiff  = dateDifferenceInDays(now, date);
	// const hours = daysDiff > 0 ? date.getHours() : date.getHours() - now.getHours();
	const hours = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
	const rtf = new Intl.RelativeTimeFormat('en', { style: 'long', numeric: 'auto' });
	return {
		days: isFinite(daysDiff) ? rtf.format(daysDiff, 'day') : '',
		hours: `at ${hours}`,
		future: date > now,
	}
}

export const convertCryptoToFiat = (cryptoData, amount, iso = 'usd') => {
	const price = cryptoData?.price?.[iso] || 0;
	let symbol;

	switch (iso.toLowerCase()) {
		case 'usd':
			symbol = '$';
			break;
		case 'eur':
			symbol = '€';
			break;
		case 'gbp':
			symbol = '£';
			break;
		case 'jpy':
		case 'cny':
			symbol = '¥';
			break;
		case 'ngn':
			symbol = '₦';
			break;
		case 'inr':
			symbol = '₹';
			break;
		case 'zar':
			symbol = 'R';
			break;			
		default:
			symbol = '$';
	}

	return `${symbol}${(amount * price).toPrecision(4)}`;
}

export const splitItemTags = (tags) => {
	// tag was intially an array
	// it's been updated to string
	// handle both cases
	if (!tags) return [];
	tags = Array.isArray(tags) ? tags.join(' ') : tags;
	return tags.trim().split(/[,\s]/); // split by comma or space
}

export const isValidEmail = (email) => {
	const re = /\S+@\S+\.\S+/;
	return re.test(email);
}