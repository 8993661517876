import React, { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useAtom } from 'jotai';
import { accountSignature, accountProfile } from 'data/account';
import { makeRequestJson } from 'utils/network';
import { requestPath } from 'app.config';

export default function RequireSignInIfWalletConnected({children}) {
    const [signingStatus, setSigningStatus] = useState('notconnected'); // pending, signed, notconnected
    const [userAccountSignature, setAccountSignature] = useAtom(accountSignature);
    const [, setUserAccount] = useAtom(accountProfile);
    const { library, account } = useWeb3React();

    useEffect(() => {
        handleSignature();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, signingStatus])
    
    async function recoverSigner(message, signature) {
        return library.eth.personal.ecRecover(message, signature);
    }

    async function handleSignature() {
        if (account) {
            if (userAccountSignature) {
                const prevSignedAccount = await recoverSigner(process.env.REACT_APP_SHARED_MESSAGE, userAccountSignature);

                if (prevSignedAccount.toLowerCase() === account.toLowerCase()) {
                    setSigningStatus('signed');
                } else {
                    setSigningStatus('pending');
                }
            } else {
                setSigningStatus('pending');
            }
        }
    }

    async function fetchUserAccount(connectingAccount, newSignature) {
        try {
          const reqData = {
            url: requestPath.profile,
            method: 'get',
                    headers: {
              'x-signature': JSON.stringify(newSignature), 
              'x-address': connectingAccount
            },
          }

          const { data = {}, success } = await makeRequestJson(reqData);
          if (success) {
            // console.log('user account', data);
            setUserAccount(data);
            setAccountSignature(newSignature);
            setSigningStatus('signed');
          }
        } catch (err) {
          console.error(err);
        }
    }

    if (account && signingStatus === 'pending') {
        return <RequestAccountSignature fetchUserAccount={fetchUserAccount} />
    }

    return children;

}

function RequestAccountSignature({fetchUserAccount}) {
    const [loading, setLoading] = useState(false);
    const { library, account, deactivate } = useWeb3React();

    async function requestSigning() {
        try {
            setLoading(true);
            const signature = await library.eth.personal.sign(process.env.REACT_APP_SHARED_MESSAGE, account);
            await fetchUserAccount(account, signature);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }


    return (
        <Container>
            <Row className="justify-content-center align-items-center">
                <Col className="text-center mt-5 pt-md-5">
                    <h2 className="h3 mb-4">You need to sign in</h2>
                    <Button 
                        disabled={loading}
                        onClick={requestSigning}
                        size="lg">
                        {loading ? 'Signing...' : 'Complete sign in'}
                    </Button>

                    <div className="mt-5">
                        <p className="text-muted">
                            Don't want to sign in?
                        </p>
                        <Button
                            variant="outline-secondary"
                            size="lg"
                            onClick={deactivate}
                        >
                            Disconnect wallet
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}