import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import CustomModal from './Modal';

export default function TermsAndConditionModal() {
    const [showModal, setShowModal] = useState(false);
    const TERM_AND_CONDITION_KEY = 'termsAndConditionsAccepted';
    useEffect(() => {
        const isAccepted = localStorage.getItem(TERM_AND_CONDITION_KEY);
        if (!isAccepted) {
            setShowModal(true);
        }
    }, []);

    const handleTermAccepted = () => {
        localStorage.setItem(TERM_AND_CONDITION_KEY, true);
        setShowModal(false);
    }

    return (
        <div>
            <CustomModal
                modalStatus={showModal}
                hideModal={() => null}
                showFooter={false}
                modalTitle={'Terms and Conditions'}
                modalBody={TermsAndCondition}
                handleTermAccepted={handleTermAccepted}
            />
        </div>
	) 
}

function TermsAndCondition({handleTermAccepted}) {
    return (
        <React.Fragment>
            <div className="terms-and-condition">
                <h2 className="h5">Welcome to Ador Opens!</h2>
                <ul className="my-3">
                    <li className="my-1">
                        <span>We will <strong>NEVER</strong> request for private key or seed phrase.</span>
                    </li>
                    <li className="my-1">
                        <span>This platform is in its beta phase, please contact us if you need help or encounter any problem.</span>
                    </li>
                    <li className="my-1">
                        <span>By accessing this website we assume you accept these &nbsp;
                            <a href="#!" className="text-decoration-none">terms and conditions</a> in full.</span>
                    </li>
                </ul>

                <div className="d-grid gap-2 my-2">
                    <Button 
                        onClick={() => handleTermAccepted()} 
                        variant="primary"
                        size="lg"
                        className="input-focus-disabled"
                    >
                        Agree to Terms
                    </Button>	
                </div>
            </div>
        </React.Fragment>
    )
}