import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useAtom } from 'jotai';
import { CText } from 'data/theme';

const spinnerStyle = {
	position: 'absolute',
	transform: 'translate(50%, -50%)',
	top: '50%',
	right: '50%'
}

export const DefaultSpinner = () => {
	const [textClass] = useAtom(CText);
	return (
		<div style={spinnerStyle}>
			<Spinner animation="border" variant={textClass}>
				<span className="visually-hidden">Loading...</span>
			</Spinner>
		</div>
	)
}


export default DefaultSpinner;