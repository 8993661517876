import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { currenciesList } from 'data/marketplace';
import { makeRequestJson } from 'utils/network';
import { requestPath } from 'app.config';

export const useCurrenciesList = (retryTimeout = 300000) => {
    const [currencies, setCurrencies] = useAtom(currenciesList);
    
    useEffect(() => {
        if (currencies.length === 0) {
            fetchCurrencies();
        } 

        const timeout = setTimeout(() => {
            fetchCurrencies();
        }, retryTimeout);

        return () => clearTimeout(timeout);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchCurrencies = async () => {
        try {
            const reqData = {
                url: requestPath.getCurrencies,
                method: 'get',
            }
            const { data = [], success } = await makeRequestJson(reqData);

            if (success) {
                setCurrencies(data);
            }
        } catch (error) {
            console.log(error);
        }
    }
}