import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useAtom } from 'jotai';
import { modalClass } from 'data/theme';

const CustomModal = ({modalStatus, centered = true, showFooter = true, hideModal, modalTitle, modalBody: ModalBody, ...otherProps}) => {
	const [modalTheme] = useAtom(modalClass);

	const handleClose = () => hideModal(false);
	return (
		<Modal
			show={modalStatus}
			onHide={handleClose}
			backdrop="static"
			keyboard={false}
			centered
		>
			<Modal.Header className={modalTheme}>
				<Modal.Title> {modalTitle} </Modal.Title>
			</Modal.Header>
			<Modal.Body className={modalTheme}>
				<ModalBody {...otherProps} />
			</Modal.Body>
			{
				showFooter && 
				<Modal.Footer className={modalTheme}>
					<Button 
						onClick={handleClose} 
						variant="primary"
						className="input-focus-disabled"
					>
						Close
					</Button>	
				</Modal.Footer>
			}
			
		</Modal>
	)
}

export default CustomModal;