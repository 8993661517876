
import { Nav, Button } from 'react-bootstrap';
import { 
    CameraVideoFill,
    FileMusicFill,
    CameraFill,
    Robot,
    PaletteFill,
    EaselFill,
    CollectionFill,
    GridFill 
} from 'react-bootstrap-icons';
import { Link, useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';
import { isDark } from 'data/theme';
import { collectionCategories } from 'app.config';

export default function CategoryNav() {
	const [isDarkTheme] = useAtom(isDark);
    const { pathname } = useLocation();
    const borderClass = isDarkTheme ? 'border-secondary' : 'border-secondary-subtle';
    
    const collectionIconProps = { size: 24, className: 'me-2' };
    const collectionIcons = {
        'all': <GridFill {...collectionIconProps} />,
        'art': <EaselFill {...collectionIconProps} />,
        'music': <FileMusicFill {...collectionIconProps} />,
        'photography': <CameraFill {...collectionIconProps} />,
        'video': <CameraVideoFill {...collectionIconProps} />,
        'collectibles': <CollectionFill {...collectionIconProps} />,
        'ai': <Robot {...collectionIconProps} />,
        'painting': <PaletteFill {...collectionIconProps} />,
    }

    return (

        <Nav 
            variant="pills" 
            className={`border-bottom border-1 ${borderClass} pb-3 justify-content-center`} 
            activeKey={`/`}>
            
            <Nav.Item>
                <Nav.Link 
                    as={Link} 
                    to={`/`}>
                    <Button 
                        variant={pathname === `/` ? 'primary' : 'outline-primary'} 
                        className="fw-bold rounded px-4 py-2 fs-5 input-focus-disabled"
                    >
                       { collectionIcons['all'] } All
                    </Button>
                </Nav.Link>
            </Nav.Item>
            {
                collectionCategories.map(({name, slug}) => (
                    <Nav.Item 
                        key={slug}>
                        <Nav.Link 
                            as={Link} 
                            to={`/marketplace/${slug}`}>
                            <Button 
                                variant={pathname === `/marketplace/${slug}` ? 'primary' : 'outline-primary'} 
                                className="fw-bold rounded px-4 py-2 fs-5 input-focus-disabled"
                            >
                                { collectionIcons[slug] }
                                { name }
                            </Button>
                        </Nav.Link>
                    </Nav.Item>
                ))
            }
        </Nav>
    )
}