import React, { useState } from 'react';
import { Dropdown, Image } from 'react-bootstrap';
import { useAtom } from 'jotai';
import { bgVariant, CText } from 'data/theme';
import { defaultFiatCurrency } from 'data/account';
import { fiatCurrencies } from 'app.config';
import CustomModal from 'components/Modal';
import FiatSelectModal from './FiatSelectModal';

export default function UserDropdownMenu() {
    const [showModal, setModalShow] = useState(false);
    const [userFiatCurrency] = useAtom(defaultFiatCurrency);
    const [dropdownVariant] = useAtom(bgVariant);
	const [textClass] = useAtom(CText);
    const { image, symbol, iso } = fiatCurrencies.find(({iso}) => iso === userFiatCurrency);

    return (
        <React.Fragment>
            <Dropdown>
                <Dropdown.Toggle variant={dropdownVariant} onClick={() => setModalShow(true)}>
                    <a href="#!" className={`text-decoration-none border-0 fs-5 ${textClass}`}>
                        <Image src={image} alt={symbol} className="me-2 border" width={24} roundedCircle /> 
                        <strong>{iso.toUpperCase()}</strong>
                    </a>
                </Dropdown.Toggle>
            </Dropdown>
            <CustomModal
                modalStatus={showModal}
                hideModal={setModalShow}
                modalTitle="Select Fiat Currency"
                modalBody={FiatSelectModal}
            />
        </React.Fragment>
    )
}