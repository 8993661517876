import { Link } from 'react-router-dom';
import { Dropdown, Image } from 'react-bootstrap';
import { useAtom } from 'jotai';
import { bgVariant, CText } from 'data/theme';
import { accountSignature } from 'data/account';
import { cutAddress } from 'utils/address';
import { useWeb3React } from '@web3-react/core';
import { imageData } from './EthIcon';

export default function UserDropdownMenu() {
    const [, setAccountSignature] = useAtom(accountSignature);
    const [dropdownVariant] = useAtom(bgVariant);
	const [textClass] = useAtom(CText);
	const { account, deactivate } = useWeb3React();

    const handleSignout = () => {
        setAccountSignature('');
        deactivate();
    }

    return (
        <Dropdown>
			<Dropdown.Toggle variant={dropdownVariant}>
				<a href="#!" className={`text-decoration-none fs-5 ${textClass}`}>
					<Image src={imageData(account)} alt="aw" className="me-2" width={36} roundedCircle /> 
					<strong>{cutAddress(account)}</strong>
				</a>
			</Dropdown.Toggle>
			<Dropdown.Menu variant={dropdownVariant}>
				<Dropdown.Item as={Link} to={`/account/${account}`} className={`fw-bold text-decoration-none fs-5 ${textClass}`}>
					Profile
				</Dropdown.Item>
				<Dropdown.Item as={Link} to={`/account/update`} className={`fw-bold text-decoration-none fs-5 ${textClass}`}>
					Set Profile
				</Dropdown.Item>
				<Dropdown.Item as={Link} to={`/currency/supported`} className={`fw-bold text-decoration-none fs-5 ${textClass}`}>
					Currencies
				</Dropdown.Item>
				<hr />
				<Dropdown.Item as="span" onClick={handleSignout}>
                    <Link to={'#!'} className={`fw-bold text-decoration-none fs-5 ${textClass}`}>Sign out</Link>
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
    )
}