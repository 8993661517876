import axios from 'axios';

export const makeRequestJson = async ({url, method = 'POST', body = {}, headers = {}}) => {
  try {
    let fetchOption = {
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      method, url
    }

    if (method.toLowerCase() === 'post') {
      fetchOption.data = JSON.stringify(body);
    }

    const req = await axios(fetchOption);
    return req.data;
    
  } catch (err) {
    if (err.response) {
      const message = err.response.data && err.response.data.message ? err.response.data.message : err.message;
      // console.log('err.response: ', err.response);
      throw new Error(message);
    } else {
      // console.log('err: ', err);
      throw new Error(err.message);
    }
  }
}

export const apiRequestJson = async ({url, method = 'POST', body = {}, headers = {}}) => {
  let fetchOption = {
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    method, url
  }

  if (method.toLowerCase() === 'post') {
    fetchOption.data = JSON.stringify(body);
  }

  if (method.toLowerCase() === 'get') {
    fetchOption.params = body;
  }  

  return axios(fetchOption);
}