import React from 'react';
import styled from 'styled-components';
import logo from 'logo.svg';
import UserDropdown from '../UserDropdown';
import FiatSelectDropdown from '../FiatSelectDropdown';
import SearchForm from '../SearchForm';
import { Link } from 'react-router-dom';
import { Container, Navbar, Nav, Button } from 'react-bootstrap';
import { BrightnessHigh, MoonFill } from 'react-bootstrap-icons';
import { useWeb3React } from '@web3-react/core';
import { useAtom } from 'jotai';
import { isDark, bgVariant } from 'data/theme';
import { navLinks } from 'app.config';

const HomeLogo = styled.img`
	max-width: 200px;
	@media (prefers-reduced-motion: no-preference) {
	  animation: App-logo-spin infinite 20s linear;
	}

	@keyframes App-logo-spin {
	  from {
	    transform: rotate(0deg);
	  }
	  to {
	    transform: rotate(360deg);
	  }
	}

`

const TopNav = () => {
	const [isDarkTheme, setTheme] = useAtom(isDark);
	const [bgClass] = useAtom(bgVariant);
	const { account } = useWeb3React();
    const borderClass = isDarkTheme ? 'border-secondary' : 'border-secondary-subtle';

	return (
		<Container fluid>
			<Navbar collapseOnSelect expand="lg" bg={bgClass} variant={bgClass} className={`border-bottom border-1 ${borderClass} mb-3`}>
				<Container fluid>
					<Navbar.Brand as={Link} to="/">
						<HomeLogo src={logo} />
						
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="mt-4 flex-md-grow-1">
							<SearchForm />
						</Nav>
						<Nav className="me-5 flex-md-grow-1">
							{
								navLinks.map(({text, link}) => (
									<Nav.Link 
										key={link} 
										as={Link} 
										to={link}
										className="fs-5 fw-bold"
									>
										{text}
									</Nav.Link>
								))
							}
						</Nav>
						<Nav className="d-flex align-items-center flex-md-grow-1">
							<span className="me-2">
								<FiatSelectDropdown />
							</span>
							<span className="pointer me-2" onClick={() => setTheme(!isDarkTheme)}>
								{
									isDarkTheme ?
									<BrightnessHigh size={32} className="me-3 my-1" title="Lights on" /> 
									:
									<MoonFill size={32} className="me-3 my-1" title="Lights off" />
								}
							</span>
							
						{	
							account ?
							<React.Fragment>
								<Button as={Link} to={'/create'} size="md" className="fw-bold fs-5 my-1 me-1">Create</Button>
								<UserDropdown />
							</React.Fragment>
							:
							<Button as={Link} to={'/connect'} size="md" className="fw-bold fs-5 my-1 me-1">Connect Wallet</Button>
						}
						</Nav>
						
					</Navbar.Collapse>
				</Container>
			</Navbar>	
		</Container>
		
	)
}

export default TopNav;